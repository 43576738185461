.main {
    .language-button {
        position: absolute;
        z-index: -1;

        top: 18px;
        right: 17px;

        width: 107px;
        height: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.42);

        color: #f2dcce;
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-decoration: none;
    }
}
