@media screen and (max-width: 800px) {
    .map {
        .map-switches {
            left: 50% !important;
            bottom: 65px !important;

            top: auto !important;
            right: auto !important;

            transform: translateX(-50%) !important;

            flex-direction: row !important;
            gap: 4px;

            .switch {
                padding: 7px;
                box-sizing: border-box;
                width: 160px !important;
                height: 35px !important;

                .switch-label {
                    font-size: 11px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 310px) {
    .map {
        .map-switches {
            width: auto;
            flex-direction: column !important;
        }
    }
}
