@media screen and (min-width: 800px) and (min-height: 570px) {
    .main {
        .language-button {
            display: none !important;
        }
    }
}

@media screen and (max-height: 570px) and (min-width: 800px) {
    .main {
        .language-button {
            top: auto;
            bottom: 45px;
            right: 30px;
        }
    }
}

@media screen and (max-width: 800px) and (max-height: 570px) {
    .main {
        .language-button {
            top: auto;
            bottom: 45px;
            right: 30px;
        }
    }
}
