.main {
    .back-button {
        position: absolute;
        z-index: 3;

        width: 100px;
        height: 37px;

        right: 22.5px;
        bottom: 18px;

        background-color: #bdc7c7;

        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        color: #304a38;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0 18px 0 18px;
        box-sizing: border-box;

        border-radius: 40px;

        cursor: pointer;
        transition: 0.2s ease-in-out;

        @media (max-aspect-ratio: 1.777777777777778) {
            transform: translateY(-8px);
        }

        img {
            width: 16px;
        }

        &:hover {
            background-color: #afb9b9;
        }
    }
}
@media screen and (max-height: 570px) {
    .main {
        .back-button {
            position: absolute;
            z-index: 3;
    
            width: 100px;
            height: 37px;
    
            right: 30px !important;
            top: auto !important;
            bottom: 45px !important;
    
            background-color: #bdc7c7;
    
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            color: #304a38;
    
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            padding: 0 18px 0 18px;
            box-sizing: border-box;
    
            border-radius: 40px;
    
            cursor: pointer;
            transition: 0.2s ease-in-out;
    
            @media (max-aspect-ratio: 1.777777777777778) {
                transform: translateY(-8px);
            }
    
            img {
                width: 16px;
            }
    
            &:hover {
                background-color: #afb9b9;
            }
            .back-button-text{
                display: none;
            }
        }
    }
}
// @media screen and (max-width: 800px) {
//     .main {
//         .back-button {
//             position: absolute;
//             z-index: 3;
    
//             width: 100px;
//             height: 37px;
    
//             right: 17px !important;
//             top: 70px !important;
    
//             background-color: #bdc7c7;
    
//             font-family: Inter;
//             font-size: 16px;
//             font-weight: 500;
//             color: #304a38;
    
//             display: flex;
//             align-items: center;
//             justify-content: space-between;
    
//             padding: 0 18px 0 18px;
//             box-sizing: border-box;
    
//             border-radius: 40px;
    
//             cursor: pointer;
//             transition: 0.2s ease-in-out;
    
//             @media (max-aspect-ratio: 1.777777777777778) {
//                 transform: translateY(-8px);
//             }
    
//             img {
//                 width: 16px;
//             }
    
//             &:hover {
//                 background-color: #afb9b9;
//             }
//             .back-button-text{
//                 display: flex;
//             }
//         }
//     }
// }