.loading {
    position: absolute;

    width: 100%;
    height: 100dvh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(204, 125, 97, 1);

    .loading-image {
        width: 55vmin;
    }
}
