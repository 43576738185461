.main {
    .zone-5 {
        .landmark {
            filter: drop-shadow(0px 0px 4px rgba(5, 5, 5, 0.25)) !important;
            opacity: 1;
            transition: 0.2s;

            pointer-events: bounding-box !important;

            // &:hover {
            //     opacity: 100%;
            // }
        }

        .zone-5-switches {
            position: absolute;
            z-index: 2;

            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: 6px;

            right: 30px;
            top: 20px;

            width: 120px;
        }

        .zone-5-area {
            path {
                stroke-width: 2 !important;
            }

            &.area-available {
                path {
                    fill: rgba(255, 255, 255, 0.438) !important;
                    stroke: rgba(255, 255, 255, 0.774) !important;

                    &:hover {
                        fill: rgba(255, 255, 255, 0.9) !important;
                    }
                }
            }

            &.area-booked {
                path {
                    fill: rgba(43, 99, 60, 0.29) !important;
                    stroke: rgba(95, 118, 102, 1) !important;

                    &:hover {
                        fill: rgba(54, 136, 80, 0.29) !important;
                    }
                }
            }

            &.area-sold {
                path {
                    fill: rgba(148, 170, 196, 0.53) !important;
                    stroke: rgba(125, 137, 151, 1) !important;

                    &:hover {
                        fill: rgba(137, 175, 221, 0.53) !important;
                    }
                }
            }
        }

        .pop-up {
            right: 185px;
            top: 21px;
        }
    }
}
