.main {
    .top-logo {
        position: absolute;

        pointer-events: none;

        display: flex;
        flex-direction: column;

        gap: 15px;

        z-index: 3;
    }

    .top-logo-alaqtar {
        left: 30px;
        top: 30px;

        img {
            width: 150px;

            pointer-events: none;
        }
    }

    .top-logo-al-zumuruda {
        left: 30px;
        top: 30px;

        img {
            width: 175px;

            pointer-events: none;
        }
    }
}
