@media screen and (max-height: 570px) {
    .main .services-button {
        border: none;
        font-size: 13px;

        width: 107px;
        height: 32px;

        left: 30px !important;
        top: auto !important;
        bottom: 45px !important;
    }

    .main .services {
        transform: scale(0.7);
    }
}

// @media screen and (max-width: 800px) {
//     .main .services-button {
//         border: none;
//         font-size: 13px;

//         width: 107px;
//         height: 32px;

//         left: 8px !important;
//         // right: 680px !important;
//         top: 263px !important;
//     }
// }

@media screen and (max-width: 800px) and (min-height: 570px) {
    .main .services-button {
        border: none;
        font-size: 13px;

        width: 107px;
        height: 32px;
        top: 18px !important;
        right: 130px;
    }
}

@media screen and (min-width: 800px) and (min-height: 570px){
    .main .services-button {
        border: none;
        font-size: 13px;
        left: 30px;
    }
}

@media screen and (max-width: 700px) {
    .main .services .services-body {
        gap: 20px;
        justify-content: space-between;

        .service-item-name {
            max-width: 82px;
        }
    }
}

@media screen and (max-width: 450px) {
    .main .services .services-body {
        flex-wrap: wrap;
        justify-content: center;
    }
}