@media screen and (max-width: 1150px) {
    .main {
        .menu-logo {
            left: 210px !important;
            bottom: 85px !important;
        }
    }
}

@media screen and (max-width: 800px) {
    .main {
        .menu-logo {
            display: none;
        }
    }
}
