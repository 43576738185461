.switch {
    max-width: 175px;
    width: 100%;
    height: 7px;

    display: flex;
    align-items: center;

    pointer-events: all;
    cursor: pointer;

    padding: 12px;
    background-color: #2E383899;

    border-radius: 40px;

    gap: 15px;
    .switch-label {
        width: 100%;
        font-size: 13px;
        color: #ffffff;
        z-index: 2;
        display: flex;
        justify-content: space-between;
    }
}
