@media screen and (max-width: 800px) {
  .main .master-plan .services-button-wrapper {
      position: absolute;
      top: 24px;
      left: 78px;
  }
}
@media screen and (max-height: 570px) {
  .main .master-plan .top-logo-al-zumuruda {
      display: block !important;
      position: absolute;
      top: 24px;
      left: 30px;
      img{
        width: 100px;
      }
  }
}
@media screen and (max-height: 460px) {
  .main .master-plan .top-logo-al-zumuruda {
      display: block !important;
      position: absolute;
      top: 24px;
      left: 100px;
      img{
        width: 100px;
      }
  }
}