.main {
    .reel {
        .controls {
            height: 55px;

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            padding: 12px;
            box-sizing: border-box;

            border-radius: 14px;

            pointer-events: all;

            background-color: #2e383880;

            .controls-button {
                width: 45px;
                height: 40px;

                background-color: #bdc7c7;

                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 11px;

                cursor: pointer;

                svg {
                    width: 18px;
                }
            }

            .controls-compass-pointer {
                position: absolute;

                top: 0;
                left: 50%;
                transform: translateX(-50%);

                img {
                    width: 20px;
                }
            }

            .controls-compass {
                overflow: hidden;

                width: 100%;
                max-width: 337.5px;
                height: 35px;

                // cursor: e-resize;

                pointer-events: inherit;

                .controls-compass-inner {
                    height: 100%;

                    display: flex;
                    pointer-events: inherit;

                    .controls-compass-image {
                        height: 100%;

                        pointer-events: inherit;

                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -o-user-select: none;
                        user-select: none;

                        -webkit-user-drag: none;
                        user-select: none;
                        -moz-user-select: none;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                    }
                }
            }
        }
    }
}
