.main {
    .services {
        position: relative;
        width: 822px;
        max-width: 94vw !important;

        display: flex;
        flex-direction: column;
        gap: 62px;

        padding: 30px 35px;
        box-sizing: border-box;
        border-radius: 20px;

        background-color: white;

        .services-close {
            position: absolute;
            right: 34px;
            top: 32px;

            cursor: pointer;

            img {
                width: 20px;
            }
        }
        .services-title {
            text-transform: uppercase;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 3%;
        }

        .services-body {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 75px;
            margin-bottom: 52px;
        }

        .service-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 18px;
            text-align: center;
            width: 100px;

            .service-item-icon {
                min-height: 75px;
            }
        }
    }
}
