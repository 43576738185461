@media screen and (max-width: 4000px) {
    .pop-up {
        position: absolute;
        z-index: 3;
    
        display: flex;
        // align-items: start;
    
        .pop-up-box {
            padding: 15px 24px 15px 24px;
            box-sizing: border-box;
    
            display: flex;
            flex-direction: column;
            gap: 9px;
    
            color: #2e3838;
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0px 5px 5px 0px #00000023;
    
            .pop-up-box-title {
                font-family: Inter;
                font-weight: 700;
                font-size: 15px;
            }
    
            .pop-up-box-desc {
                font-family: Inter;
                font-weight: 500;
                font-size: 12px;
                width: 339px;
                transition: 0.2s;
            }
        }
    
        .pop-up-triangle {
            height: 20px;
            margin-top: 10px;
            img {
                height: 100%;
            }
        }
    }
}
@media screen and (max-width: 800px) {
    .pop-up {
        position: absolute;
        z-index: 3;
    
        display: flex;
        align-items: center;
    
        .pop-up-box {
            padding: 15px 24px 15px 24px;
            box-sizing: border-box;
    
            display: flex;
            flex-direction: column;
            gap: 9px;
    
            color: #2e3838;
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0px 5px 5px 0px #00000023;
    
            .pop-up-box-title {
                font-family: Inter;
                font-weight: 700;
                font-size: 15px;
            }
    
            .pop-up-box-desc {
                font-family: Inter;
                font-weight: 500;
                font-size: 12px;
            }
        }
    
        .pop-up-triangle {
            height: 20px;
            margin-top: 0px;
            img {
                height: 100%;
            }
        }
    }
}