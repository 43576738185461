.switch {
    max-width: 175px;
    width: 100%;
    height: 7px;

    display: flex;
    align-items: center;

    pointer-events: all;
    cursor: pointer;

    padding: 14px;
    background-color: #2E3838;

    border-radius: 40px;

    gap: 15px;
    .motionCon{
        background-color: #CC7D61;
        width: 88px;
        height: 31px;
        left: -26px;
        position: absolute;
        border-radius: 40px;
        transition: left 0.3s, width 0.3s ease-in-out;
        &.state2{
            width: 62px;
            left: 56px;
            transition: left 0.3s, width 0.3s;
        }
    }
    .switch-label {
        width: 100%;
        font-size: 13px;
        color: #ffffff;
        z-index: 2;
        display: flex;
        justify-content: space-between;
    }
}
@media screen and (max-width: 800px) {
    .zone-5-switches{
        pointer-events: none;
        .switch {
            max-width: 175px;
            width: 100%;
            height: 7px;
        
            display: flex;
            align-items: center;
            margin: -15px auto;
        
            pointer-events: all !important;
            cursor: pointer;
        
            padding: 14px;
            background-color: #2E3838;
        
            border-radius: 40px;
        
            gap: 15px;
            .motionCon{
                pointer-events: all !important;
                background-color: #CC7D61;
                width: 88px;
                height: 31px;
                left: 63px;
                position: absolute;
                border-radius: 40px;
                transition: left 0.3s, width 0.3s ease-in-out;
                &.state2{
                    pointer-events: all;
                    width: 62px;
                    left: 175px;
                    transition: left 0.3s, width 0.3s;
                }
            }
            .switch-label {
                pointer-events: all;
                width: 100%;
                font-size: 13px;
                color: #ffffff;
                z-index: 2;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}