.main {
    .master-plan {
        .zone-5-area {
            path {
                fill: rgba(157, 209, 173, 0.3) !important;
                stroke-width: 4 !important;
                stroke: #639d75 !important;

                &:hover {
                    fill: rgba(136, 184, 150, 0.6) !important;
                }
            }
        }

        .zone-5-icon {
            pointer-events: none !important;
        }

        .zone-1-area {
            path {
                fill: rgba(157, 209, 173, 0.3) !important;
                stroke-width: 4 !important;
                stroke: #639d75 !important;

                &:hover {
                    fill: rgba(136, 184, 150, 0.6) !important;
                }
            }
        }

        .zone-1-icon {
            pointer-events: none !important;
        }

        .zone-2-area {
            path {
                fill: rgba(157, 209, 173, 0.3) !important;
                stroke-width: 4 !important;
                stroke: #639d75 !important;

                &:hover {
                    fill: rgba(136, 184, 150, 0.6) !important;
                }
            }
        }

        .zone-2-icon {
            pointer-events: none !important;
        }

        .zone-3-area {
            path {
                fill: rgba(151, 151, 151, 0.597) !important;
                stroke-width: 4 !important;
                stroke: #639d758a !important;

                // &:hover {
                //     fill: rgba(136, 184, 150, 0.6) !important;
                // }
            }
        }

        .zone-3-icon {
            pointer-events: none !important;
        }

        .zone-4-area {
            path {
                fill: rgba(151, 151, 151, 0.597) !important;
                stroke-width: 4 !important;
                stroke: #639d758a !important;

                // &:hover {
                //     fill: rgba(136, 184, 150, 0.6) !important;
                // }
            }
        }

        .zone-4-icon {
            pointer-events: none !important;
        }

        .vr360 {
            ellipse {
                transition: 0.2s;
            }

            &:hover {
                ellipse {
                    fill: #bb6d51;
                }
            }
        }

        .landmark {
            filter: drop-shadow(0px 0px 4px rgba(5, 5, 5, 0.25)) !important;
            opacity: 0%;
            transition: 0.2s;

            pointer-events: bounding-box !important;

            &:hover {
                opacity: 100%;
            }
        }
    }
}
