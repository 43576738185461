.main {
    .services-button {
        position: absolute;
        z-index: 4;
        // left: 30px;
        top: 230px;

        width: 175px;
        height: 33px;

        color: #304a38;
        background-color: #bdc7c7;
        border-radius: 40px;

        text-transform: uppercase;

        cursor: pointer;

        transition: 0.2s ease-in-out;

        font-family: Inter;
        font-size: 14px;
        font-weight: 600;

        border: none !important;

        &:hover {
            background-color: #afb9b9;
        }
    }
}
