@media screen and (max-width: 800px), (max-height: 400px) {
    .modal {
        padding: 0 !important;
        background-color: rgba(24, 26, 26, 0.37);

        overflow-y: auto !important;

        // &::-webkit-scrollbar {
        //     display: none;
        // }

        .modal-exit {
            top: 20px;
            right: 20px;
        }
    }
}
