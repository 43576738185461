.main {
    .menu-logo {
        position: absolute;
        left: 210px;
        bottom: 30px;

        pointer-events: none;

        display: flex;
        flex-direction: column;

        gap: 26px;

        img {
            width: 120px;
            height: 120px;

            pointer-events: none;
        }
    }
}
